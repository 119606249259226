import React, { FC } from 'react';

import { Box } from '@mui/material';

import { getLanguages, ICalendarEventType } from '@pharmaplan/common';

import EditFormGenerator from '../../EditFormGenerator';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import usePharmacistEditProfile from '../../../../../hooks/Admin/Profile/usePharmacistEditProfile';

import { pharmacistEditConfig } from './helper';
import { ICustomBack, IFromScreen } from '../../../../../actions/drawerActions';
import useSyncLocationFormik from '../../../../../hooks/Admin/Profile/useSyncLocationFormik';
import { OtherScreens } from '../../../../../helpers/Constants';
import useDynamicProperties from '../../../../../hooks/useDynamicProperties';

export interface IPharmacistEditProfile {
  pharmacistId: string;
  type: ICalendarEventType;
  fromScreen?: IFromScreen;
  hasOwner?: boolean;
  back?: ICustomBack;
}

const PharmacistEditProfile: FC<IPharmacistEditProfile> = ({
  pharmacistId,
  type,
  fromScreen,
  hasOwner,
  back,
}) => {
  const languages = useAppSelector(getLanguages);
  const { professionalUser, professionalUserDetails, capacities, capacityId } = useDynamicProperties();

  const { prefferedReplacementDurations, provinces, contactPreferences } = professionalUserDetails ?? {};
  const { selectedSoftwares, latitude, longitude } = professionalUser ?? {};

  const { formik } = usePharmacistEditProfile({
    pharmacistId,
    type,
    fromScreen,
    hasOwner,
    back,
  });

  const isDeletedPendingUser = fromScreen === OtherScreens.DeletedPendingUsers;
  const isDeletedPharmacistList = fromScreen === OtherScreens.DeletedPharmacistList;

  const preventChangeEmail = isDeletedPendingUser || isDeletedPharmacistList;

  const handleEdit = () => {
    formik.setFieldValue('accountNumber', '');
    formik.setFieldValue('isAccountNumberChanged', true);
  };

  useSyncLocationFormik({ formik, latitude, longitude });

  return (
    <Box>
      {pharmacistEditConfig({
        languages,
        pharmacistCapacities: capacities,
        prefferedReplacementDurations,
        selectedSoftwares,
        provinces,
        preventChangeEmail,
        contactPreferences,
        handleEdit,
      }).map((items) => {
        const { form, title, key } = items ?? {};

        return (
          <EditFormGenerator
            key={key}
            form={form}
            title={title}
            formik={formik}
          />
        );
      })}
    </Box>
  );
};

export default PharmacistEditProfile;
