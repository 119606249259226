import {
  getLanguages,
  getProfileUtils,
  isPharmacyAdminLoginSelector,
  istermsAgreed,
  pharmacistRegions,
  proInfo,
  ServiceTypes,
  TypeOfUser,
  userContact,
  userCoordinates,
  userPharmacyInfo,
} from '@pharmaplan/common';
import { FormikProps, FormikValues } from 'formik';
import { useAppSelector } from '../useAppSelector';
import {
  pharmacistSignupFields,
  pharmacySignupFields,
  professionalSignupFields,
} from '../../components/SignupFlow/signupForms';
import { useAppDispatch } from '../useAppDispatch';
import useSelfService from '../useSelfService';
import useProfileFormDailog from './useProfileFormDailog';
import {
  pharmacistFields,
  pharmacyField,
  professionalFields,
} from '../../components/Profile/ProfileForm/Forms';
import useUserDelete from '../useUserDelete';
import { resetDialog } from '../../reducers/dialogReducer';

const { self, full } = ServiceTypes;
const { pharmacist, pharmacy } = TypeOfUser;

const useProfileForm = (
  formik: FormikProps<any>,
  setSelected?: React.Dispatch<React.SetStateAction<string>>,
  handleEdit?: () => void,
) => {
  const dispatch = useAppDispatch();

  const {
    showPickYourLocation,
    showTermsAndConditions,
    showPictureUploadModal,
  } = useProfileFormDailog();
  const { isSelfService } = useSelfService();
  const { handleDelete } = useUserDelete();

  const allLanguages = useAppSelector(getLanguages);
  const proInfoData = useAppSelector(proInfo);
  const proUtils = useAppSelector(getProfileUtils);
  const contact = useAppSelector(userContact);
  const regions = useAppSelector(pharmacistRegions);
  const coordinates = useAppSelector(userCoordinates);
  const userPharmacy = useAppSelector(userPharmacyInfo);
  const isPharmacyAdminLogin = useAppSelector(isPharmacyAdminLoginSelector);

  const handleImageChange = (values: FormikValues) => {
    const { image, isPictureUploaded } = values ?? {};
    let form = null;

    if (image) {
      form = new FormData();
      form.append('image', image);
    }
    formik.setFieldValue('form', form);
    formik.setFieldValue('image', image);
    formik.setFieldValue('isPictureUploaded', isPictureUploaded);

    dispatch(resetDialog());
  };

  const handleChange = (e: boolean) =>
    dispatch(istermsAgreed(e));

  // Signups
  const pharmacistSignup = pharmacistSignupFields({
    proUtils,
    regions,
    showTermsAndConditions,
    showPickYourLocation,
    handleChange,
    isSelfService,
    contactPreferences: proUtils?.contactPreferences ?? [],
  });

  const professionalSignup = professionalSignupFields({
    proUtils,
    regions,
    showTermsAndConditions,
    showPickYourLocation,
    handleChange,
    isSelfService,
    contactPreferences: proUtils?.contactPreferences ?? [],
    showPictureUploadModal: showPictureUploadModal(handleImageChange),
    imageName: formik.values?.image?.name ?? '',
    isIncorporated: formik.values?.incorporated === 'true',
  });

  const pharmacySignup = pharmacySignupFields({
    softwares: proUtils?.softwares ?? [],
    provinces: proUtils?.provinces ?? [],
    banners: proUtils?.banners ?? [],
    contactPreferences: proUtils?.contactPreferences ?? [],
    showTermsAndConditions,
    showPickYourLocation,
    handleChange,
    isSelfService,
  });

  // Profiles
  const pharmacistProfile = pharmacistFields({
    languages: allLanguages,
    proInfoData,
    contact,
    proUtils,
    regions,
    isSelfService,
    handleDelete,
    setSelected,
    contactPreferences: proUtils?.contactPreferences ?? [],
  });

  const professionalProfile = professionalFields({
    languages: allLanguages,
    proInfoData,
    proUtils,
    regions,
    isSelfService,
    handleDelete,
    setSelected,
    contactPreferences: proUtils?.contactPreferences ?? [],
    isIncorporated: !!formik.values?.incorporated,
    handleEditAccount: handleEdit,
  });

  const pharmacyProfile = pharmacyField({
    languages: allLanguages,
    softwares: userPharmacy?.softwares || [],
    provinces: coordinates?.provinces ?? [],
    isSelfService,
    handleDelete,
    setSelected,
    contactPreferences: userPharmacy?.contactPreferences ?? [],
    isPharmacyAdmin: isPharmacyAdminLogin,
  });

  const formConfig = {
    [self]: {
      [pharmacist]: {
        signup: professionalSignup,
        profile: professionalProfile,
      },
      [pharmacy]: {
        signup: pharmacySignup,
        profile: pharmacyProfile,
      },
    },
    [full]: {
      [pharmacist]: {
        signup: pharmacistSignup,
        profile: pharmacistProfile,
      },
      [pharmacy]: {
        signup: pharmacySignup,
        profile: pharmacyProfile,
      },
    },
  };

  return { formConfig };
};

export default useProfileForm;
