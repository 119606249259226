import React from 'react';

import { FormikValues } from 'formik';

import TermsAndConditionsModal from '../../components/Modals/TermsAndConditionsModal';
import strings from '../../localization';
import { setDialog } from '../../reducers/dialogReducer';
import { useAppDispatch } from '../useAppDispatch';
import classes from '../../components/Profile/ProfileForm/style';
import ProfileLocationMapView from '../../components/Profile/ProfileForm/ProfileLocationMapView';
import UploadImageBase from '../../components/common/UploadDialog/UploadImageBase';

const useProfileFormDailog = () => {
  const dispatch = useAppDispatch();

  const {
    customModalHeading,
    customTitleContainer,
    mapContainerSignUpFlow,
    selectContainer,
    footerContainer,
  } = classes ?? {};

  const showTermsAndConditions = () => {
    dispatch(
      setDialog({
        heading: {
          title: strings.termsAndConditionsHeader,
        },
        Component: <TermsAndConditionsModal />,
        maxWidth: 'xl',
        customStyle: {
          customModalHeading,
          customTitleContainer,
        },
        showCloseButton: true,
      }),
    );
  };

  const showPickYourLocation = () => {
    dispatch(
      setDialog({
        heading: {
          title: strings.pickYourLocationOnMap,
        },
        Component: (
          <ProfileLocationMapView
            customStyles={{
              container: mapContainerSignUpFlow,
              subContainer: selectContainer,
              footer: footerContainer,
            }}
            isSignup
          />
        ),
        maxWidth: 'lg',
        customStyle: { customModalHeading },
        showCloseButton: true,
      }),
    );
  };

  const showPictureUploadModal = (onSubmit?: (values: FormikValues) => void) =>
    () => {
      dispatch(
        setDialog({
          maxWidth: 'xs',
          showCloseButton: true,
          Component: <UploadImageBase onSubmit={onSubmit} />,
          heading: {
            title: strings.uploadPicture,
          },
        }),
      );
    };

  return {
    showTermsAndConditions,
    showPickYourLocation,
    showPictureUploadModal,
  };
};

export default useProfileFormDailog;
