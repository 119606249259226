import { SxProps, Theme } from '@mui/system';

const styles = {
  boxContainer: {
    display: 'flex',
    flex: 1,
    mx: '8px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fieldContainer: {
    flex: 9,
  },
  buttonContainer: {
    flex: 1,
  },
  buttonLabel: {
    textDecoration: 'underline',
  },
  startAdornment: {
    border: '1px solid',
    borderColor: 'common.borderInput',
    paddingY: '7px',
    paddingX: '8px',
    backgroundColor: 'secondary.main',
    marginRight: '4px',
    borderRadius: '8px 0 0 8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  field: {
    '& .MuiInputBase-root': {
      paddingLeft: 0,
    },
  },
  dialogMessage: {
    fontSize: 18,
    fontWeight: '500',
    textAlign: 'center',
  },
} satisfies Record<string, SxProps<Theme>>;

export default styles;
