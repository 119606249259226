import React, { FC } from 'react';

import { Box } from '@mui/system';
import { FormikProps } from 'formik';
import { Grid } from '@mui/material';

import CustomTextField from '../CustomTextField';

import styles from './styles';

interface IConfirmTextField {
  handleClick: () => void;
  name: string;
  formik: FormikProps<any>;
  startAdornment?: React.ReactNode;
  xs: number;
  label: string;
  maxLength?: number;
}

const ConfirmTextField: FC<IConfirmTextField> = ({
  handleClick,
  name,
  formik,
  startAdornment,
  xs,
  label,
  maxLength,
}) =>
  (
    <Grid xs={xs} container>
      <Box sx={styles.boxContainer}>

        <Box sx={styles.fieldContainer} onClick={handleClick}>
          <CustomTextField
            maxLength={maxLength}
            customTextFieldStyle={styles.field}
            startAdornment={startAdornment}
            formik={formik}
            label={label}
            id={name}
            name={name}
          />
        </Box>
      </Box>
    </Grid>
  );

export default ConfirmTextField;
