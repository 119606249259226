import { FormikValues } from 'formik';

import {
  getBookedRating as getPharmacistBookedRating,
  getPharmacyBookedRating,
  getPharmacyRatingItems,
  getRatingItems as getPharmacistRatingItems,
  getTypeOfUser,
  pharmacyBookedRating,
  pharmacyRatingItems,
  rate,
  ratePharmacy,
  TypeOfUser,
  userIdSelector,
  professionalBookedRating,
  professionalRatingItemsSelector,
} from '@pharmaplan/common';

import { useEffect, useState } from 'react';
import { useAppDispatch } from '../useAppDispatch';
import { useAppSelector } from '../useAppSelector';
import { resetDialog } from '../../reducers/dialogReducer';
import strings from '../../localization';

const { pharmacist, pharmacy } = TypeOfUser;

export interface IUsePharmacistRatingCommon {
  id: string;
  bookingId: string;
  ratingLevel: { [key: string]: number };
  favState: boolean;
}

const usePharmacistRating = ({
  id,
  bookingId,
  ratingLevel,
  favState,
}: IUsePharmacistRatingCommon) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(userIdSelector);

  const userType = useAppSelector(getTypeOfUser);

  const pharmacyRatingData = useAppSelector(pharmacyRatingItems);
  const profRatingItems = useAppSelector(professionalRatingItemsSelector);

  const profBookedRating = useAppSelector(professionalBookedRating);
  const pharmacyBookedRatingData = useAppSelector(pharmacyBookedRating);

  const {
    pharmacyRatingItems: pharmacyRatingItemList,
    pharmacyRatingQuestions,
    displayDetailForm: pharmacyDisplayDetailForm,
  } = pharmacyRatingData ?? {};
  const { professionalRatingItems, displayDetailForm: profDisplayDetailForm } = profRatingItems ?? {};

  const { data: professionalData, comment: professionalComment } = profBookedRating ?? {};
  const { data: pharmacyData, comment: pharmacyComment } = pharmacyBookedRatingData ?? {};

  const [ratingFeedback, setRatingFeedback] = useState<{
    [key: string]: number;
  }>({});

  const professionalHandleSubmit = (values: FormikValues) => {
    const levels = pharmacyRatingItemList.map((item) => {
      const { pharmacyRatingItemId } = item ?? {};
      return {
        pharmacyRatingItemId,
        level: ratingLevel[pharmacyRatingItemId] ?? 0,
      };
    });

    const answers = pharmacyRatingQuestions.map((item) => {
      const { pharmacyRatingQuestionId } = item ?? {};
      return {
        pharmacyRatingQuestionId,
        answer: values?.[pharmacyRatingQuestionId] ?? 0,
      };
    });

    const { comment } = values ?? {};

    dispatch(
      ratePharmacy({
        bookingId: bookingId ?? '',
        levels: pharmacyDisplayDetailForm ? [...levels] : [],
        favorite: favState,
        comment,
        professionalId: userId,
        pharmacyId: id,
        answers: [...answers],
      }),
    );
    dispatch(resetDialog());
  };

  const pharmacyHandleSubmit = (values: FormikValues) => {
    const levels = professionalRatingItems.map((item) => {
      const { professionalRatingItemId } = item ?? {};
      return {
        professionalRatingItemId,
        level: ratingLevel[professionalRatingItemId] ?? 0,
      };
    });

    const answers = profRatingItems?.professionalRatingQuestions?.map(
      (item) => {
        const { professionalRatingQuestionId } = item ?? {};
        return {
          professionalRatingQuestionId,
          answer: values?.[professionalRatingQuestionId] ?? 0,
        };
      },
    );

    const { comment } = values ?? {};

    dispatch(
      rate({
        bookingId: bookingId ?? '',
        levels: profDisplayDetailForm ? [...levels] : [],
        favorite: favState,
        comment,
        professionalId: id,
        answers,
      }),
    );
    dispatch(resetDialog());
  };

  const config = {
    [pharmacy]: {
      getRatingItems: getPharmacistRatingItems,
      getBookedRating: getPharmacistBookedRating,
      userRatingItems: professionalRatingItems,
      handleSubmit: pharmacyHandleSubmit,
      data: professionalData.professionalRatings,
      comment: professionalComment,
      ratingData: profRatingItems,
      bookedRatingData: professionalBookedRating,
      idProperty: 'professionalRatingItemId',
      questions: profRatingItems?.professionalRatingQuestions,
      questionProperty: 'professionalRatingQuestionId',
      answers: professionalData?.professionalRatingAnswers,
      displayDetailForm: profDisplayDetailForm,
      title: strings.myPharmacistRating,
    },
    [pharmacist]: {
      getRatingItems: getPharmacyRatingItems,
      getBookedRating: getPharmacyBookedRating,
      userRatingItems: pharmacyRatingItemList,
      handleSubmit: professionalHandleSubmit,
      data: pharmacyData.pharmacyRatings,
      comment: pharmacyComment,
      ratingData: pharmacyRatingData,
      bookedRatingData: pharmacyBookedRatingData,
      idProperty: 'pharmacyRatingItemId',
      questions: pharmacyRatingData?.pharmacyRatingQuestions,
      questionProperty: 'pharmacyRatingQuestionId',
      answers: pharmacyData?.pharmacyRatingAnswers,
      displayDetailForm: pharmacyDisplayDetailForm,
      title: strings.myPharmacyRating,
    },
  };

  const {
    getRatingItems,
    getBookedRating,
    userRatingItems,
    handleSubmit,
    data,
    comment,
    ratingData,
    idProperty,
    bookedRatingData,
    questions,
    questionProperty,
    answers,
    displayDetailForm,
    title,
  } = config[userType as keyof typeof config];
  console.log(data);

  const maxRatedItemsPossible = displayDetailForm ? userRatingItems?.length : 0;

  useEffect(() => {
    dispatch(getRatingItems(id));
    dispatch(getBookedRating(bookingId ?? ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRatingFeedback(
      (answers as any)?.reduce((acc: Record<string, number>, cur: any) => {
        acc[cur?.[questionProperty as keyof typeof cur] ?? ''] = cur?.answer ?? 0;
        return acc;
      }, {}),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  return {
    data,
    bookedComment: comment,
    maxRatedItemsPossible,
    handleSubmit,
    ratingData,
    bookedRatingData,
    userRatingItems,
    idProperty,
    displayDetailForm,
    questions,
    ratingFeedback,
    title,
    questionProperty,
  };
};

export default usePharmacistRating;
