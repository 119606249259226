import {
  getLanguages,
  logos,
  IFilterSoftware,
  IPharmacistCapacities,
  IContactPreferences,
  selectPharmacistPitures,
  IPublishedSoftwareList,
  IProfessionalCapacity,
} from '@pharmaplan/common';
import { idStore } from '@pharmaplan/common/helpers/ServiceHandler';
import { useAppSelector } from './useAppSelector';
import DefaultBanner from '../assets/svg/defaultBanner.svg';

const useGetItems = () => {
  const allLogos = useAppSelector(logos);
  const allLanguages = useAppSelector(getLanguages);
  const pharmacistPictures = useAppSelector(selectPharmacistPitures);

  const getSoftwareName = (
    data: IFilterSoftware[] | IPublishedSoftwareList[],
    softwareId: string,
  ) =>
    data.find((item) =>
      item.softwareId === softwareId)?.name ?? '';

  const getImage = (logoId: string) =>
    allLogos.find((item) =>
      item.logoId === logoId)?.image ?? DefaultBanner;

  const getLanguageName = (languageId: string) =>
    allLanguages.find((item) =>
      item.languageId === languageId)?.name ?? '';

  const getLanguageCode = (languageId: string) =>
    allLanguages.find((item) =>
      item.languageId === languageId)?.code ?? '';

  const getPharmacyName = (banners: any[]) =>
    banners.map((item) => {
      const name = allLogos.find(
        (logoItem) =>
          logoItem.pharmacyChainId === item.pharmacyChainId,
      )?.name ?? '';

      return {
        name,
        pharmacyChainId: item.pharmacyChainId,
      };
    });

  const getPharmacistCapacities = (
    data: (IPharmacistCapacities | IProfessionalCapacity)[],
    pharmacistCapacityId: string,
  ) =>
    data?.find(
      (item) =>
        item?.[idStore.capacityId as keyof typeof item] === pharmacistCapacityId,
    )?.description ?? '';

  const getSelectedContactPreferences = (
    data: Array<IContactPreferences>,
    selectedContactPreferences: Array<string>,
  ) =>
    selectedContactPreferences
      ?.map((id) =>
        data?.find((item) =>
          id === item.contactPreferenceId)?.name)
      .join(', ');

  const getPharmacistImage = (pharmacistId: string) =>
    pharmacistPictures.find((item) =>
      item.pharmacistId === pharmacistId)
      ?.image;

  const getPharmacyChain = (pharmacyChainId: string) =>
    allLogos.find((item) =>
      item.pharmacyChainId === pharmacyChainId)?.image
    ?? DefaultBanner;

  return {
    getImage,
    getLanguageName,
    getSoftwareName,
    getPharmacistCapacities,
    getSelectedContactPreferences,
    getPharmacistImage,
    getLanguageCode,
    getPharmacyChain,
    getPharmacyName,
  };
};

export default useGetItems;
