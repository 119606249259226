import ServiceHandler from '@pharmaplan/common/helpers/ServiceHandler';
import { adminPharmacistSearchedDetailsFilters, adminProfessionalDetails, pharmacistDetails, ServiceTypes } from '@pharmaplan/common';
import { useAppSelector } from './useAppSelector';

const { self, full } = ServiceTypes;

const useDynamicProperties = () => {
  const serviceType = ServiceHandler.getService();
  const pharmacistInformation = useAppSelector(pharmacistDetails);
  const professionalInformation = useAppSelector(adminProfessionalDetails);
  const filterData = useAppSelector(adminPharmacistSearchedDetailsFilters);

  const { pharmacist } = pharmacistInformation ?? {};
  const { professional } = professionalInformation ?? {};

  const dynamicProperties = {
    [self]: {
      professionalUserDetails: professionalInformation,
      professionalUser: professional,
      capacityId: professional?.professionalCapacityId,
      capacities: professionalInformation?.professionalCapacities,
      id: professional?.professionalId,
      filterCapacity: filterData?.professionalCapacities,
    },
    [full]: {
      professionalUserDetails: pharmacistInformation,
      professionalUser: pharmacist,
      capacityId: pharmacist?.pharmacistCapacityId,
      capacities: pharmacistInformation?.pharmacistCapacities,
      id: pharmacist?.pharmacistId,
      filterCapacity: filterData?.pharmacistCapacities,
    },
  };

  return dynamicProperties[serviceType];
};

export default useDynamicProperties;
